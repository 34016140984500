// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root {
	--ion-color-primary: #FF671F;
	--ion-color-primary-rgb: 255,103,31;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #e05b1b;
	--ion-color-primary-tint: #ff7635;

	--ion-color-secondary: #0097e6;
	--ion-color-secondary-rgb: 0,151,230;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #0085ca;
	--ion-color-secondary-tint: #1aa1e9;

	--ion-color-tertiary: #FFBE76;
	--ion-color-tertiary-rgb: 255,190,118;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #e0a768;
	--ion-color-tertiary-tint: #ffc584;

	--ion-color-success: #2dd55b;
	--ion-color-success-rgb: 45,213,91;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28bb50;
	--ion-color-success-tint: #42d96b;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #c5000f;
	--ion-color-danger-rgb: 197,0,15;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ad000d;
	--ion-color-danger-tint: #cb1a27;

	--ion-color-light: #f6f8fc;
	--ion-color-light-rgb: 246,248,252;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d8dade;
	--ion-color-light-tint: #f7f9fc;

	--ion-color-medium: #5f5f5f;
	--ion-color-medium-rgb: 95,95,95;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #545454;
	--ion-color-medium-tint: #6f6f6f;

	--ion-color-dark: #2f2f2f;
	--ion-color-dark-rgb: 47,47,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #292929;
	--ion-color-dark-tint: #444444;

    --ion-color-good: #0984e3;
	--ion-color-good-rgb: 9, 132, 227;
	--ion-color-good-contrast: #ffffff;
	--ion-color-good-contrast-rgb: 0, 0, 0;
	--ion-color-good-shade: #0874c8;
	--ion-color-good-tint: #2290e6;

	--c-blue-0: #f0f6ff;
    --c-blue-10: #e3edff;
    --c-blue-20: #cddfff;
    --c-blue-30: #b2ceff;
    --c-blue-40: #97bdff;
    --c-blue-50: #7cabff;
    --c-blue-60: #639bff;
    --c-blue-70: #4d8dff;
    --c-blue-80: #3880ff;
    --c-blue-90: #176bff;
    --c-blue-100: #0054e9;
    --c-blue-110: #004dd6;
    --c-blue-120: #0046c1;
    --c-blue-130: #003fae;
    --c-blue-140: #00389b;
    --c-blue-150: #002d7c;
    --c-blue-160: #002669;
    --c-blue-170: #001d52;
    --c-blue-180: #001740;
    --c-blue-190: #00112f;
    --c-blue-200: #000b1f;
    --c-gray-0: #f3f3f3;
    --c-gray-10: #e4e4e4;
    --c-gray-20: #c8c8c8;
    --c-gray-30: #aeaeae;
    --c-gray-40: #959595;
    --c-gray-50: #818181;
    --c-gray-60: #6d6d6d;
    --c-gray-70: #5f5f5f;
    --c-gray-80: #474747;
    --c-gray-90: #2f2f2f;
    --c-gray-100: #141414;
    --c-carbon-0: #eef1f3;
    --c-carbon-10: #d7dde2;
    --c-carbon-20: #b4bcc6;
    --c-carbon-30: #98a2ad;
    --c-carbon-40: #7d8894;
    --c-carbon-50: #677483;
    --c-carbon-60: #556170;
    --c-carbon-70: #434f5e;
    --c-carbon-80: #35404e;
    --c-carbon-90: #222d3a;
    --c-carbon-100: #03060b;
    --c-indigo-0: #fbfbfd;
    --c-indigo-10: #f6f8fc;
    --c-indigo-20: #e9edf3;
    --c-indigo-30: #dee3ea;
    --c-indigo-40: #ced6e0;
    --c-indigo-50: #b2becd;
    --c-indigo-60: #92a0b3;
    --c-indigo-70: #73849a;
    --c-indigo-80: #445b78;
    --c-indigo-90: #2d4665;
    --c-indigo-100: #001a3a;
    --c-green-0: #f1fdf5;
    --c-green-10: #deffe7;
    --c-green-20: #c7fbd5;
    --c-green-30: #a7f1bb;
    --c-green-40: #80e89d;
    --c-green-50: #62e085;
    --c-green-60: #4ada71;
    --c-green-70: #2dd55b;
    --c-green-80: #17c948;
    --c-green-90: #00ba33;
    --c-green-100: #00a52d;
    --c-green-110: #009b2b;
    --c-green-120: #009128;
    --c-green-130: #008725;
    --c-green-140: #007d22;
    --c-green-150: #00711f;
    --c-green-160: #00661c;
    --c-green-170: #00581a;
    --c-green-180: #004314;
    --c-green-190: #002f0e;
    --c-green-200: #001807;
    --c-lime-0: #f5fff0;
    --c-lime-10: #ebfee3;
    --c-lime-20: #ddfcd0;
    --c-lime-30: #cffbbc;
    --c-lime-40: #bbf9a2;
    --c-lime-50: #a3f581;
    --c-lime-60: #8bf35f;
    --c-lime-70: #64ec44;
    --c-lime-80: #4ddf2b;
    --c-lime-90: #3ad515;
    --c-lime-100: #27c100;
    --c-lime-110: #25b400;
    --c-lime-120: #22a400;
    --c-lime-130: #1e9200;
    --c-lime-140: #1a7e00;
    --c-lime-150: #176d00;
    --c-lime-160: #135a00;
    --c-lime-170: #0f4900;
    --c-lime-180: #0c3900;
    --c-lime-190: #092c00;
    --c-lime-200: #061d00;
    --c-lavender-0: #f7f8ff;
    --c-lavender-10: #e6ebff;
    --c-lavender-20: #ced9ff;
    --c-lavender-30: #b6c6ff;
    --c-lavender-40: #9fb5ff;
    --c-lavender-50: #8aa4ff;
    --c-lavender-60: #7493ff;
    --c-lavender-70: #597eff;
    --c-lavender-80: #3c67ff;
    --c-lavender-90: #194bfd;
    --c-lavender-100: #0033e8;
    --c-lavender-110: #002dcc;
    --c-lavender-120: #0028b8;
    --c-lavender-130: #0023a2;
    --c-lavender-140: #002092;
    --c-lavender-150: #001a79;
    --c-lavender-160: #001560;
    --c-lavender-170: #00114e;
    --c-lavender-180: #000e41;
    --c-lavender-190: #000a30;
    --c-lavender-200: #000721;
    --c-purple-0: #f4f4ff;
    --c-purple-10: #e9eaff;
    --c-purple-20: #d0d2ff;
    --c-purple-30: #b6b9f9;
    --c-purple-40: #9a99fc;
    --c-purple-50: #8482fb;
    --c-purple-60: #786df9;
    --c-purple-70: #6e5afd;
    --c-purple-80: #6030ff;
    --c-purple-90: #4712fb;
    --c-purple-100: #3400e6;
    --c-purple-110: #3000d1;
    --c-purple-120: #2b00bc;
    --c-purple-130: #2600a6;
    --c-purple-140: #20008e;
    --c-purple-150: #1b0075;
    --c-purple-160: #15005c;
    --c-purple-170: #100048;
    --c-purple-180: #0d0038;
    --c-purple-190: #0b0030;
    --c-purple-200: #080022;
    --c-pink-0: #ffeff5;
    --c-pink-10: #ffe3ed;
    --c-pink-20: #ffd8e5;
    --c-pink-30: #ffc9db;
    --c-pink-40: #ffb6d0;
    --c-pink-50: #ff99bd;
    --c-pink-60: #ff80ac;
    --c-pink-70: #ff6098;
    --c-pink-80: #fb4082;
    --c-pink-90: #ec216a;
    --c-pink-100: #da0d56;
    --c-pink-110: #d0004a;
    --c-pink-120: #c40046;
    --c-pink-130: #b30040;
    --c-pink-140: #a3003b;
    --c-pink-150: #940035;
    --c-pink-160: #850030;
    --c-pink-170: #710029;
    --c-pink-180: #5f0022;
    --c-pink-190: #460019;
    --c-pink-200: #20000b;
    --c-red-0: #fff1f3;
    --c-red-10: #ffe6e8;
    --c-red-20: #ffcfd3;
    --c-red-30: #feb7bc;
    --c-red-40: #fc9aa2;
    --c-red-50: #f9838c;
    --c-red-60: #f56570;
    --c-red-70: #f24c58;
    --c-red-80: #ef3442;
    --c-red-90: #e21827;
    --c-red-100: #d0000f;
    --c-red-110: #c5000f;
    --c-red-120: #b3000e;
    --c-red-130: #9c000c;
    --c-red-140: #89000b;
    --c-red-150: #760009;
    --c-red-160: #650008;
    --c-red-170: #520006;
    --c-red-180: #410005;
    --c-red-190: #300004;
    --c-red-200: #1d0002;
    --c-orange-0: #fff5f0;
    --c-orange-10: #ffede6;
    --c-orange-20: #ffdfd1;
    --c-orange-30: #ffd0bc;
    --c-orange-40: #ffc0a5;
    --c-orange-50: #ffaf8c;
    --c-orange-60: #ff9b70;
    --c-orange-70: #ff8753;
    --c-orange-80: #ff7336;
    --c-orange-90: #ff5b13;
    --c-orange-100: #eb4700;
    --c-orange-110: #d94200;
    --c-orange-120: #c93d00;
    --c-orange-130: #b63700;
    --c-orange-140: #a53200;
    --c-orange-150: #8c2a00;
    --c-orange-160: #772400;
    --c-orange-170: #5e1c00;
    --c-orange-180: #481600;
    --c-orange-190: #341000;
    --c-orange-200: #1d0900;
    --c-yellow-0: #fffbef;
    --c-yellow-10: #fff8e2;
    --c-yellow-20: #fff4d1;
    --c-yellow-30: #ffefbd;
    --c-yellow-40: #ffe9a3;
    --c-yellow-50: #ffe080;
    --c-yellow-60: #ffd75a;
    --c-yellow-70: #ffce31;
    --c-yellow-80: #ffc409;
    --c-yellow-90: #f4b100;
    --c-yellow-100: #eaa100;
    --c-yellow-110: #dd9800;
    --c-yellow-120: #cc8d00;
    --c-yellow-130: #be8300;
    --c-yellow-140: #b17a00;
    --c-yellow-150: #9c6c00;
    --c-yellow-160: #8a6000;
    --c-yellow-170: #755100;
    --c-yellow-180: #5f4100;
    --c-yellow-190: #452f00;
    --c-yellow-200: #231800;
    --c-aqua-0: #f0fff9;
    --c-aqua-10: #e6fff6;
    --c-aqua-20: #ceffed;
    --c-aqua-30: #b7fce3;
    --c-aqua-40: #93f9d5;
    --c-aqua-50: #79f5c9;
    --c-aqua-60: #59f0ba;
    --c-aqua-70: #38e9aa;
    --c-aqua-80: #1ae19a;
    --c-aqua-90: #00d287;
    --c-aqua-100: #00ba78;
    --c-aqua-110: #00aa6d;
    --c-aqua-120: #009b63;
    --c-aqua-130: #00915c;
    --c-aqua-140: #008152;
    --c-aqua-150: #016e46;
    --c-aqua-160: #015d3c;
    --c-aqua-170: #014f32;
    --c-aqua-180: #013e28;
    --c-aqua-190: #012e1e;
    --c-aqua-200: #011e13;
    --c-teal-0: #eefeff;
    --c-teal-10: #dffdff;
    --c-teal-20: #d0fdff;
    --c-teal-30: #bbfcff;
    --c-teal-40: #a2fcff;
    --c-teal-50: #8bfbff;
    --c-teal-60: #73f6fb;
    --c-teal-70: #55ecf2;
    --c-teal-80: #35e2e9;
    --c-teal-90: #1bd2d9;
    --c-teal-100: #00b9c0;
    --c-teal-110: #01adb4;
    --c-teal-120: #019fa5;
    --c-teal-130: #018f94;
    --c-teal-140: #017e83;
    --c-teal-150: #016d71;
    --c-teal-160: #015d61;
    --c-teal-170: #014d4f;
    --c-teal-180: #013c3e;
    --c-teal-190: #012c2e;
    --c-teal-200: #011c1d;
    --c-cyan-0: #f3faff;
    --c-cyan-10: #e8f5ff;
    --c-cyan-20: #d3ecff;
    --c-cyan-30: #bfe4ff;
    --c-cyan-40: #a7daff;
    --c-cyan-50: #8dcfff;
    --c-cyan-60: #77c6ff;
    --c-cyan-70: #62bdff;
    --c-cyan-80: #46b1ff;
    --c-cyan-90: #24a3ff;
    --c-cyan-100: #0091fa;
    --c-cyan-110: #0189ec;
    --c-cyan-120: #017ed8;
    --c-cyan-130: #0170c0;
    --c-cyan-140: #0163aa;
    --c-cyan-150: #015592;
    --c-cyan-160: #01487b;
    --c-cyan-170: #013a64;
    --c-cyan-180: #012d4d;
    --c-cyan-190: #011e33;
    --c-cyan-200: #01121e;
}
